.device-preview-card {
  width: 810px;
  height: -moz-fit-content;
  height: fit-content;
  background: #222;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  margin-top: 20px;
}

.device-preview-card h3 {
  margin-bottom: 12px;
  color: #fff;
  font-size: 35px;
  line-height: 50px;
  font-weight: 500;
  width: 100%;
  &.warning {
    color: #ff0000;
  }
}

.device-preview-card .dropzone {
  border: 2px dashed #7f7f7f;
  width: 20em;
  height: 10em;
}
