.pac.content-area {
  flex-direction: row;
  align-items: stretch;
  width: 100%;
}

.pac.content-area .compare-area {
  min-width: 200px;
  width: 20%;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  flex-direction: column;
}
.pac .line {
  width: 2px;
  min-height: 200px;
  height: 100%;
  background: #444444;
  margin: 30px 0px;
}
.base.note-area {
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
}
.base.note-area.text {
  flex-direction: column;
  font-family: monospace;
  font-size: 16px;
  color: #ddd;
  width: 100%;
  padding: 0px 10px;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
}
.big-card-container {
  flex-grow: 1;
}
p.pac-diff-added {
  color: lightgreen;
  overflow-wrap: break-word;
  word-break: break-word;
}
p.pac-diff-removed {
  color: red;
  overflow-wrap: break-word;
  word-break: break-word;
}
p.pac-diff-changed {
  color: yellow;
  overflow-wrap: break-word;
  word-break: break-word;
}
h3.pac-diff-header {
  color: #fff;
  overflow-wrap: break-word;
  word-break: break-word;
}
.lds-loader-container {
  width: 810px;
  min-height: 560px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.lds-grid {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-grid div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #46d401;
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 8px;
  left: 8px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 8px;
  left: 32px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 8px;
  left: 56px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 32px;
  left: 8px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 32px;
  left: 32px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 32px;
  left: 56px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 56px;
  left: 8px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 56px;
  left: 32px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 56px;
  left: 56px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
