@import '../../../../node_modules/@crossnokaye/ux-common/dist/styles/theme.css';
@import './ux-common-base.css';

/* plugin seems to need these directives here despite being present in the globals.css file from ux-common.
I suspect it's something to do with esbuilds handling of css imports. */
*, ::before, ::after{
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  ;
}
::backdrop{
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-inset:  ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(59 130 246 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  ;
    --tw-contain-size:  ;
    --tw-contain-layout:  ;
    --tw-contain-paint:  ;
    --tw-contain-style:  ;
}
/** Prevent the little white square in the corner of the scrollbar */
*::-webkit-scrollbar-corner {
    background: transparent;
  }
.\!container{
    width: 100% !important;
    margin-right: auto !important;
    margin-left: auto !important;
    padding-right: 2rem !important;
    padding-left: 2rem !important;
}
.container{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 2rem;
    padding-left: 2rem;
}
@media (min-width: 1400px){
    .\!container{
        max-width: 1400px !important;
    }
    .container{
        max-width: 1400px;
    }
}
.sr-only{
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}
.pointer-events-none{
    pointer-events: none;
}
.visible{
    visibility: visible;
}
.invisible{
    visibility: hidden;
}
.collapse{
    visibility: collapse;
}
.static{
    position: static;
}
.fixed{
    position: fixed;
}
.absolute{
    position: absolute;
}
.relative{
    position: relative;
}
.sticky{
    position: sticky;
}
.inset-0{
    inset: 0px;
}
.inset-x-0{
    left: 0px;
    right: 0px;
}
.inset-y-0{
    top: 0px;
    bottom: 0px;
}
.-left-\[8px\]{
    left: -8px;
}
.-right-1{
    right: -0.25rem;
}
.-right-px{
    right: -1px;
}
.bottom-0{
    bottom: 0px;
}
.bottom-1{
    bottom: 0.25rem;
}
.bottom-2{
    bottom: 0.5rem;
}
.left-0{
    left: 0px;
}
.left-1{
    left: 0.25rem;
}
.left-1\/2{
    left: 50%;
}
.left-2{
    left: 0.5rem;
}
.left-3{
    left: 0.75rem;
}
.left-\[-8px\]{
    left: -8px;
}
.left-\[50\%\]{
    left: 50%;
}
.right-0{
    right: 0px;
}
.right-1{
    right: 0.25rem;
}
.right-1\.5{
    right: 0.375rem;
}
.right-2{
    right: 0.5rem;
}
.right-3{
    right: 0.75rem;
}
.right-4{
    right: 1rem;
}
.right-\[24px\]{
    right: 24px;
}
.top-0{
    top: 0px;
}
.top-1{
    top: 0.25rem;
}
.top-1\/2{
    top: 50%;
}
.top-2{
    top: 0.5rem;
}
.top-4{
    top: 1rem;
}
.top-\[1px\]{
    top: 1px;
}
.top-\[50\%\]{
    top: 50%;
}
.top-\[60\%\]{
    top: 60%;
}
.top-full{
    top: 100%;
}
.z-0{
    z-index: 0;
}
.z-10{
    z-index: 10;
}
.z-50{
    z-index: 50;
}
.z-\[102\]{
    z-index: 102;
}
.z-\[103\]{
    z-index: 103;
}
.z-\[10\]{
    z-index: 10;
}
.z-\[150\]{
    z-index: 150;
}
.z-\[1\]{
    z-index: 1;
}
.z-\[200\]{
    z-index: 200;
}
.z-\[201\]{
    z-index: 201;
}
.z-\[202\]{
    z-index: 202;
}
.z-\[203\]{
    z-index: 203;
}
.z-\[5\]{
    z-index: 5;
}
.z-\[999\]{
    z-index: 999;
}
.\!m-auto{
    margin: auto !important;
}
.m-0{
    margin: 0px;
}
.m-2\.5{
    margin: 0.625rem;
}
.m-7{
    margin: 1.75rem;
}
.m-auto{
    margin: auto;
}
.-mx-1{
    margin-left: -0.25rem;
    margin-right: -0.25rem;
}
.mx-0{
    margin-left: 0px;
    margin-right: 0px;
}
.mx-1{
    margin-left: 0.25rem;
    margin-right: 0.25rem;
}
.mx-16{
    margin-left: 4rem;
    margin-right: 4rem;
}
.mx-2\.5{
    margin-left: 0.625rem;
    margin-right: 0.625rem;
}
.mx-3{
    margin-left: 0.75rem;
    margin-right: 0.75rem;
}
.mx-4{
    margin-left: 1rem;
    margin-right: 1rem;
}
.mx-5{
    margin-left: 1.25rem;
    margin-right: 1.25rem;
}
.mx-auto{
    margin-left: auto;
    margin-right: auto;
}
.my-0{
    margin-top: 0px;
    margin-bottom: 0px;
}
.my-1{
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}
.my-2{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}
.my-4{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.my-6{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.my-\[2px\]{
    margin-top: 2px;
    margin-bottom: 2px;
}
.my-auto{
    margin-top: auto;
    margin-bottom: auto;
}
.-mr-1{
    margin-right: -0.25rem;
}
.-mt-0\.5{
    margin-top: -0.125rem;
}
.mb-0\.5{
    margin-bottom: 0.125rem;
}
.mb-1{
    margin-bottom: 0.25rem;
}
.mb-10{
    margin-bottom: 2.5rem;
}
.mb-12{
    margin-bottom: 3rem;
}
.mb-2{
    margin-bottom: 0.5rem;
}
.mb-20{
    margin-bottom: 5rem;
}
.mb-3{
    margin-bottom: 0.75rem;
}
.mb-4{
    margin-bottom: 1rem;
}
.mb-5{
    margin-bottom: 1.25rem;
}
.mb-6{
    margin-bottom: 1.5rem;
}
.mb-8{
    margin-bottom: 2rem;
}
.mb-\[4\.5rem\]{
    margin-bottom: 4.5rem;
}
.ml-1{
    margin-left: 0.25rem;
}
.ml-2{
    margin-left: 0.5rem;
}
.ml-\[-1px\]{
    margin-left: -1px;
}
.ml-\[0\.3125rem\]{
    margin-left: 0.3125rem;
}
.ml-auto{
    margin-left: auto;
}
.mr-0\.5{
    margin-right: 0.125rem;
}
.mr-1{
    margin-right: 0.25rem;
}
.mr-2{
    margin-right: 0.5rem;
}
.mr-4{
    margin-right: 1rem;
}
.mr-5{
    margin-right: 1.25rem;
}
.mt-0{
    margin-top: 0px;
}
.mt-1{
    margin-top: 0.25rem;
}
.mt-1\.5{
    margin-top: 0.375rem;
}
.mt-2{
    margin-top: 0.5rem;
}
.mt-24{
    margin-top: 6rem;
}
.mt-3{
    margin-top: 0.75rem;
}
.mt-4{
    margin-top: 1rem;
}
.mt-5{
    margin-top: 1.25rem;
}
.mt-6{
    margin-top: 1.5rem;
}
.mt-\[-0\.5rem\]{
    margin-top: -0.5rem;
}
.mt-auto{
    margin-top: auto;
}
.box-border{
    box-sizing: border-box;
}
.box-content{
    box-sizing: content-box;
}
.line-clamp-1{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
.block{
    display: block;
}
.inline-block{
    display: inline-block;
}
.inline{
    display: inline;
}
.flex{
    display: flex;
}
.inline-flex{
    display: inline-flex;
}
.\!table{
    display: table !important;
}
.table{
    display: table;
}
.table-cell{
    display: table-cell;
}
.grid{
    display: grid;
}
.contents{
    display: contents;
}
.hidden{
    display: none;
}
.aspect-square{
    aspect-ratio: 1 / 1;
}
.\!h-10{
    height: 2.5rem !important;
}
.\!h-auto{
    height: auto !important;
}
.h-0{
    height: 0px;
}
.h-1\.5{
    height: 0.375rem;
}
.h-10{
    height: 2.5rem;
}
.h-11{
    height: 2.75rem;
}
.h-12{
    height: 3rem;
}
.h-16{
    height: 4rem;
}
.h-2{
    height: 0.5rem;
}
.h-2\.5{
    height: 0.625rem;
}
.h-3{
    height: 0.75rem;
}
.h-3\.5{
    height: 0.875rem;
}
.h-32{
    height: 8rem;
}
.h-4{
    height: 1rem;
}
.h-40{
    height: 10rem;
}
.h-5{
    height: 1.25rem;
}
.h-5\/6{
    height: 83.333333%;
}
.h-52{
    height: 13rem;
}
.h-56{
    height: 14rem;
}
.h-6{
    height: 1.5rem;
}
.h-7{
    height: 1.75rem;
}
.h-8{
    height: 2rem;
}
.h-80{
    height: 20rem;
}
.h-9{
    height: 2.25rem;
}
.h-\[1\.625rem\]{
    height: 1.625rem;
}
.h-\[1\.875rem\]{
    height: 1.875rem;
}
.h-\[10px\]{
    height: 10px;
}
.h-\[15px\]{
    height: 15px;
}
.h-\[1px\]{
    height: 1px;
}
.h-\[1rem\]{
    height: 1rem;
}
.h-\[2\.875rem\]{
    height: 2.875rem;
}
.h-\[24px\]{
    height: 24px;
}
.h-\[3\.375rem\]{
    height: 3.375rem;
}
.h-\[30px\]{
    height: 30px;
}
.h-\[32px\]{
    height: 32px;
}
.h-\[35px\]{
    height: 35px;
}
.h-\[40px\]{
    height: 40px;
}
.h-\[46px\]{
    height: 46px;
}
.h-\[50px\]{
    height: 50px;
}
.h-\[58px\]{
    height: 58px;
}
.h-\[80px\]{
    height: 80px;
}
.h-\[80vh\]{
    height: 80vh;
}
.h-\[90px\]{
    height: 90px;
}
.h-\[calc\(95vh-8rem\)\]{
    height: calc(95vh - 8rem);
}
.h-\[var\(--radix-navigation-menu-viewport-height\)\]{
    height: var(--radix-navigation-menu-viewport-height);
}
.h-\[var\(--radix-select-trigger-height\)\]{
    height: var(--radix-select-trigger-height);
}
.h-auto{
    height: auto;
}
.h-fit{
    height: -moz-fit-content;
    height: fit-content;
}
.h-full{
    height: 100%;
}
.h-px{
    height: 1px;
}
.max-h-10{
    max-height: 2.5rem;
}
.max-h-16{
    max-height: 4rem;
}
.max-h-5{
    max-height: 1.25rem;
}
.max-h-52{
    max-height: 13rem;
}
.max-h-56{
    max-height: 14rem;
}
.max-h-8{
    max-height: 2rem;
}
.max-h-80{
    max-height: 20rem;
}
.max-h-96{
    max-height: 24rem;
}
.max-h-\[--radix-popover-content-available-height\]{
    max-height: var(--radix-popover-content-available-height);
}
.max-h-\[300px\]{
    max-height: 300px;
}
.max-h-\[400px\]{
    max-height: 400px;
}
.max-h-\[5\.125rem\]{
    max-height: 5.125rem;
}
.max-h-\[65vh\]{
    max-height: 65vh;
}
.max-h-\[66vh\]{
    max-height: 66vh;
}
.max-h-\[70px\]{
    max-height: 70px;
}
.max-h-\[80px\]{
    max-height: 80px;
}
.max-h-\[95vh\]{
    max-height: 95vh;
}
.max-h-full{
    max-height: 100%;
}
.min-h-0{
    min-height: 0px;
}
.min-h-10{
    min-height: 2.5rem;
}
.min-h-14{
    min-height: 3.5rem;
}
.min-h-16{
    min-height: 4rem;
}
.min-h-40{
    min-height: 10rem;
}
.min-h-5{
    min-height: 1.25rem;
}
.min-h-52{
    min-height: 13rem;
}
.min-h-56{
    min-height: 14rem;
}
.min-h-8{
    min-height: 2rem;
}
.min-h-80{
    min-height: 20rem;
}
.min-h-\[20px\]{
    min-height: 20px;
}
.min-h-\[40px\]{
    min-height: 40px;
}
.min-h-\[5\.125rem\]{
    min-height: 5.125rem;
}
.min-h-\[50px\]{
    min-height: 50px;
}
.min-h-\[80px\]{
    min-height: 80px;
}
.w-0{
    width: 0px;
}
.w-1\/2{
    width: 50%;
}
.w-1\/4{
    width: 25%;
}
.w-10{
    width: 2.5rem;
}
.w-11{
    width: 2.75rem;
}
.w-12{
    width: 3rem;
}
.w-16{
    width: 4rem;
}
.w-2{
    width: 0.5rem;
}
.w-2\.5{
    width: 0.625rem;
}
.w-2\/3{
    width: 66.666667%;
}
.w-20{
    width: 5rem;
}
.w-28{
    width: 7rem;
}
.w-3{
    width: 0.75rem;
}
.w-3\.5{
    width: 0.875rem;
}
.w-3\/4{
    width: 75%;
}
.w-32{
    width: 8rem;
}
.w-4{
    width: 1rem;
}
.w-40{
    width: 10rem;
}
.w-44{
    width: 11rem;
}
.w-48{
    width: 12rem;
}
.w-5{
    width: 1.25rem;
}
.w-56{
    width: 14rem;
}
.w-60{
    width: 15rem;
}
.w-64{
    width: 16rem;
}
.w-7{
    width: 1.75rem;
}
.w-72{
    width: 18rem;
}
.w-8{
    width: 2rem;
}
.w-9{
    width: 2.25rem;
}
.w-96{
    width: 24rem;
}
.w-\[--radix-popover-trigger-width\]{
    width: var(--radix-popover-trigger-width);
}
.w-\[1\.625rem\]{
    width: 1.625rem;
}
.w-\[10px\]{
    width: 10px;
}
.w-\[11\.25rem\]{
    width: 11.25rem;
}
.w-\[120px\]{
    width: 120px;
}
.w-\[150px\]{
    width: 150px;
}
.w-\[15px\]{
    width: 15px;
}
.w-\[17\.5rem\]{
    width: 17.5rem;
}
.w-\[180px\]{
    width: 180px;
}
.w-\[1px\]{
    width: 1px;
}
.w-\[1rem\]{
    width: 1rem;
}
.w-\[20\%\]{
    width: 20%;
}
.w-\[200px\]{
    width: 200px;
}
.w-\[24px\]{
    width: 24px;
}
.w-\[250px\]{
    width: 250px;
}
.w-\[260px\]{
    width: 260px;
}
.w-\[28rem\]{
    width: 28rem;
}
.w-\[2px\]{
    width: 2px;
}
.w-\[3\.75rem\]{
    width: 3.75rem;
}
.w-\[30px\]{
    width: 30px;
}
.w-\[4\.375rem\]{
    width: 4.375rem;
}
.w-\[40px\]{
    width: 40px;
}
.w-\[5px\]{
    width: 5px;
}
.w-\[80\%\]{
    width: 80%;
}
.w-\[90\%\]{
    width: 90%;
}
.w-\[950px\]{
    width: 950px;
}
.w-\[calc\(96vw-3rem\)\]{
    width: calc(96vw - 3rem);
}
.w-auto{
    width: auto;
}
.w-fit{
    width: -moz-fit-content;
    width: fit-content;
}
.w-full{
    width: 100%;
}
.w-max{
    width: -moz-max-content;
    width: max-content;
}
.w-min{
    width: -moz-min-content;
    width: min-content;
}
.w-px{
    width: 1px;
}
.min-w-0{
    min-width: 0px;
}
.min-w-20{
    min-width: 5rem;
}
.min-w-28{
    min-width: 7rem;
}
.min-w-48{
    min-width: 12rem;
}
.min-w-5{
    min-width: 1.25rem;
}
.min-w-56{
    min-width: 14rem;
}
.min-w-96{
    min-width: 24rem;
}
.min-w-\[10rem\]{
    min-width: 10rem;
}
.min-w-\[12\.25rem\]{
    min-width: 12.25rem;
}
.min-w-\[1200px\]{
    min-width: 1200px;
}
.min-w-\[12rem\]{
    min-width: 12rem;
}
.min-w-\[20px\]{
    min-width: 20px;
}
.min-w-\[28rem\]{
    min-width: 28rem;
}
.min-w-\[400px\]{
    min-width: 400px;
}
.min-w-\[50px\]{
    min-width: 50px;
}
.min-w-\[650px\]{
    min-width: 650px;
}
.min-w-\[8rem\]{
    min-width: 8rem;
}
.min-w-\[980px\]{
    min-width: 980px;
}
.min-w-\[var\(--radix-select-trigger-width\)\]{
    min-width: var(--radix-select-trigger-width);
}
.min-w-full{
    min-width: 100%;
}
.max-w-0{
    max-width: 0px;
}
.max-w-3xl{
    max-width: 48rem;
}
.max-w-4xl{
    max-width: 56rem;
}
.max-w-6xl{
    max-width: 72rem;
}
.max-w-\[36rem\]{
    max-width: 36rem;
}
.max-w-\[500px\]{
    max-width: 500px;
}
.max-w-\[50vw\]{
    max-width: 50vw;
}
.max-w-\[600px\]{
    max-width: 600px;
}
.max-w-\[720px\]{
    max-width: 720px;
}
.max-w-\[73rem\]{
    max-width: 73rem;
}
.max-w-\[95vw\]{
    max-width: 95vw;
}
.max-w-\[96vw\]{
    max-width: 96vw;
}
.max-w-full{
    max-width: 100%;
}
.max-w-lg{
    max-width: 32rem;
}
.max-w-max{
    max-width: -moz-max-content;
    max-width: max-content;
}
.max-w-sm{
    max-width: 24rem;
}
.max-w-xl{
    max-width: 36rem;
}
.flex-1{
    flex: 1 1 0%;
}
.flex-auto{
    flex: 1 1 auto;
}
.flex-none{
    flex: none;
}
.flex-shrink{
    flex-shrink: 1;
}
.flex-shrink-0{
    flex-shrink: 0;
}
.shrink{
    flex-shrink: 1;
}
.shrink-0{
    flex-shrink: 0;
}
.flex-grow{
    flex-grow: 1;
}
.flex-grow-0{
    flex-grow: 0;
}
.grow{
    flex-grow: 1;
}
.basis-1\/2{
    flex-basis: 50%;
}
.basis-6\/12{
    flex-basis: 50%;
}
.table-auto{
    table-layout: auto;
}
.table-fixed{
    table-layout: fixed;
}
.border-collapse{
    border-collapse: collapse;
}
.border-separate{
    border-collapse: separate;
}
.border-spacing-0{
    --tw-border-spacing-x: 0px;
    --tw-border-spacing-y: 0px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}
.-translate-x-1\/2{
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-x-full{
    --tw-translate-x: -100%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-1{
    --tw-translate-y: -0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.-translate-y-1\/2{
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-0{
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-x-\[-50\%\]{
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.translate-y-\[-50\%\]{
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-180{
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-45{
    --tw-rotate: 45deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.rotate-90{
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform{
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes pulse{
    50%{
        opacity: .5;
    }
}
.animate-pulse{
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
@keyframes pulse-scale{
    0%{
        transform: scale(1);
        opacity: 1;
    }
    100%{
        transform: scale(1.5);
        opacity: 0;
    }
}
.animate-pulse-scale{
    animation: pulse-scale 2s infinite;
}
@keyframes spin{
    to{
        transform: rotate(360deg);
    }
}
.animate-spin{
    animation: spin 1s linear infinite;
}
.cursor-col-resize{
    cursor: col-resize;
}
.cursor-default{
    cursor: default;
}
.cursor-not-allowed{
    cursor: not-allowed;
}
.cursor-pointer{
    cursor: pointer;
}
.touch-none{
    touch-action: none;
}
.select-none{
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}
.resize-none{
    resize: none;
}
.resize{
    resize: both;
}
.list-none{
    list-style-type: none;
}
.appearance-none{
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}
.grid-cols-2{
    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-4{
    grid-template-columns: repeat(4, minmax(0, 1fr));
}
.flex-row{
    flex-direction: row;
}
.flex-row-reverse{
    flex-direction: row-reverse;
}
.flex-col{
    flex-direction: column;
}
.flex-col-reverse{
    flex-direction: column-reverse;
}
.flex-wrap{
    flex-wrap: wrap;
}
.content-center{
    align-content: center;
}
.items-start{
    align-items: flex-start;
}
.items-end{
    align-items: flex-end;
}
.items-center{
    align-items: center;
}
.items-stretch{
    align-items: stretch;
}
.justify-start{
    justify-content: flex-start;
}
.justify-end{
    justify-content: flex-end;
}
.justify-center{
    justify-content: center;
}
.justify-between{
    justify-content: space-between;
}
.gap-1{
    gap: 0.25rem;
}
.gap-1\.5{
    gap: 0.375rem;
}
.gap-10{
    gap: 2.5rem;
}
.gap-12{
    gap: 3rem;
}
.gap-2{
    gap: 0.5rem;
}
.gap-3{
    gap: 0.75rem;
}
.gap-4{
    gap: 1rem;
}
.gap-5{
    gap: 1.25rem;
}
.gap-6{
    gap: 1.5rem;
}
.gap-8{
    gap: 2rem;
}
.gap-\[0\.0625rem\]{
    gap: 0.0625rem;
}
.gap-\[0\.1rem\]{
    gap: 0.1rem;
}
.gap-\[0\.3125rem\]{
    gap: 0.3125rem;
}
.gap-\[0\.9375rem\]{
    gap: 0.9375rem;
}
.gap-\[10px\]{
    gap: 10px;
}
.gap-\[2px\]{
    gap: 2px;
}
.gap-x-1{
    -moz-column-gap: 0.25rem;
         column-gap: 0.25rem;
}
.gap-x-1\.5{
    -moz-column-gap: 0.375rem;
         column-gap: 0.375rem;
}
.gap-x-2{
    -moz-column-gap: 0.5rem;
         column-gap: 0.5rem;
}
.gap-x-3{
    -moz-column-gap: 0.75rem;
         column-gap: 0.75rem;
}
.gap-x-4{
    -moz-column-gap: 1rem;
         column-gap: 1rem;
}
.gap-x-5{
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem;
}
.gap-y-2{
    row-gap: 0.5rem;
}
.gap-y-3{
    row-gap: 0.75rem;
}
.space-x-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-y-0 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
}
.space-y-1 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.space-y-1\.5 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
}
.space-y-2 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.space-y-4 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}
.space-y-6 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}
.space-y-8 > :not([hidden]) ~ :not([hidden]){
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}
.self-start{
    align-self: flex-start;
}
.justify-self-end{
    justify-self: end;
}
.justify-self-center{
    justify-self: center;
}
.\!overflow-auto{
    overflow: auto !important;
}
.overflow-auto{
    overflow: auto;
}
.overflow-hidden{
    overflow: hidden;
}
.overflow-visible{
    overflow: visible;
}
.overflow-x-auto{
    overflow-x: auto;
}
.overflow-y-auto{
    overflow-y: auto;
}
.overflow-x-hidden{
    overflow-x: hidden;
}
.overflow-y-visible{
    overflow-y: visible;
}
.overflow-x-scroll{
    overflow-x: scroll;
}
.overflow-y-scroll{
    overflow-y: scroll;
}
.truncate{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.text-ellipsis{
    text-overflow: ellipsis;
}
.whitespace-normal{
    white-space: normal;
}
.whitespace-nowrap{
    white-space: nowrap;
}
.whitespace-break-spaces{
    white-space: break-spaces;
}
.break-words{
    overflow-wrap: break-word;
}
.break-all{
    word-break: break-all;
}
.rounded{
    border-radius: 0.25rem;
}
.rounded-\[25px\]{
    border-radius: 25px;
}
.rounded-\[2px\]{
    border-radius: 2px;
}
.rounded-\[inherit\]{
    border-radius: inherit;
}
.rounded-full{
    border-radius: 9999px;
}
.rounded-lg{
    border-radius: calc(var(--radius) * 2);
}
.rounded-md{
    border-radius: calc(var(--radius));
}
.rounded-none{
    border-radius: 0px;
}
.rounded-sm{
    border-radius: calc(var(--radius) - 4px);
}
.rounded-b-lg{
    border-bottom-right-radius: calc(var(--radius) * 2);
    border-bottom-left-radius: calc(var(--radius) * 2);
}
.rounded-l-none{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.rounded-r-none{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.rounded-t-\[10px\]{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.rounded-tl-sm{
    border-top-left-radius: calc(var(--radius) - 4px);
}
.border{
    border-width: 1px;
}
.border-0{
    border-width: 0px;
}
.border-2{
    border-width: 2px;
}
.border-4{
    border-width: 4px;
}
.border-\[1px\]{
    border-width: 1px;
}
.border-\[2px\]{
    border-width: 2px;
}
.border-x{
    border-left-width: 1px;
    border-right-width: 1px;
}
.border-y{
    border-top-width: 1px;
    border-bottom-width: 1px;
}
.border-b{
    border-bottom-width: 1px;
}
.border-b-2{
    border-bottom-width: 2px;
}
.border-b-8{
    border-bottom-width: 8px;
}
.border-b-\[3px\]{
    border-bottom-width: 3px;
}
.border-l{
    border-left-width: 1px;
}
.border-l-2{
    border-left-width: 2px;
}
.border-r{
    border-right-width: 1px;
}
.border-r-2{
    border-right-width: 2px;
}
.border-r-8{
    border-right-width: 8px;
}
.border-t{
    border-top-width: 1px;
}
.border-t-8{
    border-top-width: 8px;
}
.border-solid{
    border-style: solid;
}
.border-dashed{
    border-style: dashed;
}
.border-none{
    border-style: none;
}
.border-\[\#999\]{
    --tw-border-opacity: 1;
    border-color: rgb(153 153 153 / var(--tw-border-opacity, 1));
}
.border-\[\#CCC\]{
    --tw-border-opacity: 1;
    border-color: rgb(204 204 204 / var(--tw-border-opacity, 1));
}
.border-background{
    border-color: hsl(var(--background));
}
.border-black{
    --tw-border-opacity: 1;
    border-color: rgb(0 0 0 / var(--tw-border-opacity, 1));
}
.border-border{
    border-color: hsl(var(--border));
}
.border-caution{
    border-color: hsl(var(--caution));
}
.border-critical{
    border-color: hsl(var(--critical));
}
.border-destructive{
    border-color: hsl(var(--destructive));
}
.border-foreground{
    border-color: hsl(var(--foreground));
}
.border-foreground\/50{
    border-color: hsl(var(--foreground) / 0.5);
}
.border-input{
    border-color: hsl(var(--input));
}
.border-neutral-300{
    --tw-border-opacity: 1;
    border-color: rgb(176 176 176 / var(--tw-border-opacity, 1));
}
.border-primary{
    border-color: hsl(var(--primary));
}
.border-primary-foreground{
    border-color: hsl(var(--primary-foreground));
}
.border-secondary{
    border-color: hsl(var(--secondary));
}
.border-tertiary{
    border-color: hsl(var(--tertiary));
}
.border-transparent{
    border-color: transparent;
}
.border-warning{
    border-color: hsl(var(--warning));
}
.border-b-\[\#f6f6f6\]{
    --tw-border-opacity: 1;
    border-bottom-color: rgb(246 246 246 / var(--tw-border-opacity, 1));
}
.border-l-background{
    border-left-color: hsl(var(--background));
}
.border-l-transparent{
    border-left-color: transparent;
}
.border-r-\[\#FFFFFF\]{
    --tw-border-opacity: 1;
    border-right-color: rgb(255 255 255 / var(--tw-border-opacity, 1));
}
.border-r-background{
    border-right-color: hsl(var(--background));
}
.border-t-transparent{
    border-top-color: transparent;
}
.\!bg-neutral-950{
    --tw-bg-opacity: 1 !important;
    background-color: rgb(38 38 38 / var(--tw-bg-opacity, 1)) !important;
}
.\!bg-transparent{
    background-color: transparent !important;
}
.bg-\[\#1B1B1B\]{
    --tw-bg-opacity: 1;
    background-color: rgb(27 27 27 / var(--tw-bg-opacity, 1));
}
.bg-\[\#30381F\]{
    --tw-bg-opacity: 1;
    background-color: rgb(48 56 31 / var(--tw-bg-opacity, 1));
}
.bg-\[\#444\]{
    --tw-bg-opacity: 1;
    background-color: rgb(68 68 68 / var(--tw-bg-opacity, 1));
}
.bg-\[\#CCC\]{
    --tw-bg-opacity: 1;
    background-color: rgb(204 204 204 / var(--tw-bg-opacity, 1));
}
.bg-\[\#FFFFFF\]{
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.bg-accent{
    background-color: hsl(var(--accent));
}
.bg-accent\/50{
    background-color: hsl(var(--accent) / 0.5);
}
.bg-background{
    background-color: hsl(var(--background));
}
.bg-black{
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity, 1));
}
.bg-black\/80{
    background-color: rgb(0 0 0 / 0.8);
}
.bg-border{
    background-color: hsl(var(--border));
}
.bg-card{
    background-color: hsl(var(--card));
}
.bg-caution{
    background-color: hsl(var(--caution));
}
.bg-cooling{
    background-color: hsl(var(--cooling));
}
.bg-critical{
    background-color: hsl(var(--critical));
}
.bg-critical\/20{
    background-color: hsl(var(--critical) / 0.2);
}
.bg-destructive{
    background-color: hsl(var(--destructive));
}
.bg-foreground{
    background-color: hsl(var(--foreground));
}
.bg-input{
    background-color: hsl(var(--input));
}
.bg-muted{
    background-color: hsl(var(--muted));
}
.bg-muted-foreground{
    background-color: hsl(var(--muted-foreground));
}
.bg-muted-foreground\/20{
    background-color: hsl(var(--muted-foreground) / 0.2);
}
.bg-muted\/50{
    background-color: hsl(var(--muted) / 0.5);
}
.bg-neutral-200{
    --tw-bg-opacity: 1;
    background-color: rgb(209 209 209 / var(--tw-bg-opacity, 1));
}
.bg-neutral-600{
    --tw-bg-opacity: 1;
    background-color: rgb(93 93 93 / var(--tw-bg-opacity, 1));
}
.bg-neutral-800{
    --tw-bg-opacity: 1;
    background-color: rgb(69 69 69 / var(--tw-bg-opacity, 1));
}
.bg-neutral-900{
    --tw-bg-opacity: 1;
    background-color: rgb(61 61 61 / var(--tw-bg-opacity, 1));
}
.bg-neutral-950{
    --tw-bg-opacity: 1;
    background-color: rgb(38 38 38 / var(--tw-bg-opacity, 1));
}
.bg-popover{
    background-color: hsl(var(--popover));
}
.bg-primary{
    background-color: hsl(var(--primary));
}
.bg-primary\/10{
    background-color: hsl(var(--primary) / 0.1);
}
.bg-primary\/20{
    background-color: hsl(var(--primary) / 0.2);
}
.bg-primary\/30{
    background-color: hsl(var(--primary) / 0.3);
}
.bg-secondary{
    background-color: hsl(var(--secondary));
}
.bg-tertiary{
    background-color: hsl(var(--tertiary));
}
.bg-transparent{
    background-color: transparent;
}
.bg-warning{
    background-color: hsl(var(--warning));
}
.bg-warning\/10{
    background-color: hsl(var(--warning) / 0.1);
}
.bg-white{
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
.bg-opacity-50{
    --tw-bg-opacity: 0.5;
}
.bg-gradient-to-br{
    background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}
.from-\[\#c602ff\]{
    --tw-gradient-from: #c602ff var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(198 2 255 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
.to-\[\#6533eb\]{
    --tw-gradient-to: #6533eb var(--tw-gradient-to-position);
}
.fill-cooling{
    fill: hsl(var(--cooling));
}
.fill-current{
    fill: currentColor;
}
.fill-running{
    fill: hsl(var(--running));
}
.fill-white{
    fill: #fff;
}
.stroke-black{
    stroke: #000;
}
.stroke-1{
    stroke-width: 1;
}
.p-0{
    padding: 0px;
}
.p-1{
    padding: 0.25rem;
}
.p-1\.5{
    padding: 0.375rem;
}
.p-10{
    padding: 2.5rem;
}
.p-2{
    padding: 0.5rem;
}
.p-2\.5{
    padding: 0.625rem;
}
.p-3{
    padding: 0.75rem;
}
.p-4{
    padding: 1rem;
}
.p-5{
    padding: 1.25rem;
}
.p-6{
    padding: 1.5rem;
}
.p-\[0\.0625rem\]{
    padding: 0.0625rem;
}
.p-\[10px\]{
    padding: 10px;
}
.p-\[1px\]{
    padding: 1px;
}
.px-0{
    padding-left: 0px;
    padding-right: 0px;
}
.px-1{
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
.px-10{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}
.px-16{
    padding-left: 4rem;
    padding-right: 4rem;
}
.px-2{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.px-2\.5{
    padding-left: 0.625rem;
    padding-right: 0.625rem;
}
.px-3{
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}
.px-3\.5{
    padding-left: 0.875rem;
    padding-right: 0.875rem;
}
.px-4{
    padding-left: 1rem;
    padding-right: 1rem;
}
.px-5{
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}
.px-6{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.px-8{
    padding-left: 2rem;
    padding-right: 2rem;
}
.px-\[20px\]{
    padding-left: 20px;
    padding-right: 20px;
}
.py-0{
    padding-top: 0px;
    padding-bottom: 0px;
}
.py-0\.5{
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
}
.py-1{
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}
.py-1\.5{
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}
.py-2{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.py-2\.5{
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}
.py-3{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.py-4{
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.py-6{
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.py-\[0\.1875rem\]{
    padding-top: 0.1875rem;
    padding-bottom: 0.1875rem;
}
.py-\[0\.5\]{
    padding-top: 0.5;
    padding-bottom: 0.5;
}
.pb-0{
    padding-bottom: 0px;
}
.pb-10{
    padding-bottom: 2.5rem;
}
.pb-2{
    padding-bottom: 0.5rem;
}
.pb-3{
    padding-bottom: 0.75rem;
}
.pb-4{
    padding-bottom: 1rem;
}
.pb-5{
    padding-bottom: 1.25rem;
}
.pb-7{
    padding-bottom: 1.75rem;
}
.pb-\[21px\]{
    padding-bottom: 21px;
}
.pl-1{
    padding-left: 0.25rem;
}
.pl-10{
    padding-left: 2.5rem;
}
.pl-2{
    padding-left: 0.5rem;
}
.pl-2\.5{
    padding-left: 0.625rem;
}
.pl-3{
    padding-left: 0.75rem;
}
.pl-4{
    padding-left: 1rem;
}
.pl-5{
    padding-left: 1.25rem;
}
.pl-8{
    padding-left: 2rem;
}
.pl-\[2px\]{
    padding-left: 2px;
}
.pr-1{
    padding-right: 0.25rem;
}
.pr-1\.5{
    padding-right: 0.375rem;
}
.pr-10{
    padding-right: 2.5rem;
}
.pr-2{
    padding-right: 0.5rem;
}
.pr-2\.5{
    padding-right: 0.625rem;
}
.pr-3{
    padding-right: 0.75rem;
}
.pr-4{
    padding-right: 1rem;
}
.pr-\[5px\]{
    padding-right: 5px;
}
.pt-0{
    padding-top: 0px;
}
.pt-1{
    padding-top: 0.25rem;
}
.pt-10{
    padding-top: 2.5rem;
}
.pt-3{
    padding-top: 0.75rem;
}
.pt-4{
    padding-top: 1rem;
}
.pt-6{
    padding-top: 1.5rem;
}
.pt-\[0\.75rem\]{
    padding-top: 0.75rem;
}
.pt-\[2\.1875rem\]{
    padding-top: 2.1875rem;
}
.text-left{
    text-align: left;
}
.text-center{
    text-align: center;
}
.text-right{
    text-align: right;
}
.text-start{
    text-align: start;
}
.align-top{
    vertical-align: top;
}
.align-middle{
    vertical-align: middle;
}
.align-bottom{
    vertical-align: bottom;
}
.align-text-bottom{
    vertical-align: text-bottom;
}
.font-\[\"Red_Hat_Display\"\]{
    font-family: "Red Hat Display";
}
.font-mono{
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
.text-2xl{
    font-size: 1.5rem;
    line-height: 2rem;
}
.text-3xl{
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.text-\[0\.625rem\]{
    font-size: 0.625rem;
}
.text-\[0\.75rem\]{
    font-size: 0.75rem;
}
.text-\[0\.8rem\]{
    font-size: 0.8rem;
}
.text-base{
    font-size: 1rem;
    line-height: 1.5rem;
}
.text-lg{
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.text-sm{
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.text-xl{
    font-size: 1.25rem;
    line-height: 1.75rem;
}
.text-xs{
    font-size: 0.75rem;
    line-height: 1rem;
}
.font-bold{
    font-weight: 700;
}
.font-medium{
    font-weight: 500;
}
.font-normal{
    font-weight: 400;
}
.font-semibold{
    font-weight: 600;
}
.uppercase{
    text-transform: uppercase;
}
.lowercase{
    text-transform: lowercase;
}
.capitalize{
    text-transform: capitalize;
}
.normal-case{
    text-transform: none;
}
.italic{
    font-style: italic;
}
.slashed-zero{
    --tw-slashed-zero: slashed-zero;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}
.tabular-nums{
    --tw-numeric-spacing: tabular-nums;
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}
.leading-10{
    line-height: 2.5rem;
}
.leading-5{
    line-height: 1.25rem;
}
.leading-6{
    line-height: 1.5rem;
}
.leading-7{
    line-height: 1.75rem;
}
.leading-8{
    line-height: 2rem;
}
.leading-\[1\.125rem\]{
    line-height: 1.125rem;
}
.leading-\[24px\]{
    line-height: 24px;
}
.leading-\[30px\]{
    line-height: 30px;
}
.leading-loose{
    line-height: 2;
}
.leading-none{
    line-height: 1;
}
.tracking-tight{
    letter-spacing: -0.025em;
}
.tracking-wide{
    letter-spacing: 0.025em;
}
.tracking-widest{
    letter-spacing: 0.1em;
}
.text-\[\#666\]{
    --tw-text-opacity: 1;
    color: rgb(102 102 102 / var(--tw-text-opacity, 1));
}
.text-accent-foreground{
    color: hsl(var(--accent-foreground));
}
.text-background{
    color: hsl(var(--background));
}
.text-black{
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}
.text-card-foreground{
    color: hsl(var(--card-foreground));
}
.text-caution-foreground{
    color: hsl(var(--caution-foreground));
}
.text-cooling-foreground{
    color: hsl(var(--cooling-foreground));
}
.text-critical{
    color: hsl(var(--critical));
}
.text-critical-foreground{
    color: hsl(var(--critical-foreground));
}
.text-current{
    color: currentColor;
}
.text-destructive{
    color: hsl(var(--destructive));
}
.text-destructive-foreground{
    color: hsl(var(--destructive-foreground));
}
.text-foreground{
    color: hsl(var(--foreground));
}
.text-foreground\/50{
    color: hsl(var(--foreground) / 0.5);
}
.text-muted{
    color: hsl(var(--muted));
}
.text-muted-foreground{
    color: hsl(var(--muted-foreground));
}
.text-neutral-200{
    --tw-text-opacity: 1;
    color: rgb(209 209 209 / var(--tw-text-opacity, 1));
}
.text-neutral-300{
    --tw-text-opacity: 1;
    color: rgb(176 176 176 / var(--tw-text-opacity, 1));
}
.text-neutral-400{
    --tw-text-opacity: 1;
    color: rgb(136 136 136 / var(--tw-text-opacity, 1));
}
.text-neutral-700{
    --tw-text-opacity: 1;
    color: rgb(81 81 81 / var(--tw-text-opacity, 1));
}
.text-popover-foreground{
    color: hsl(var(--popover-foreground));
}
.text-primary{
    color: hsl(var(--primary));
}
.text-primary-foreground{
    color: hsl(var(--primary-foreground));
}
.text-secondary{
    color: hsl(var(--secondary));
}
.text-secondary-foreground{
    color: hsl(var(--secondary-foreground));
}
.text-tertiary-foreground{
    color: hsl(var(--tertiary-foreground));
}
.text-warning{
    color: hsl(var(--warning));
}
.text-warning-foreground{
    color: hsl(var(--warning-foreground));
}
.text-white{
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
.underline{
    text-decoration-line: underline;
}
.line-through{
    text-decoration-line: line-through;
}
.no-underline{
    text-decoration-line: none;
}
.underline-offset-4{
    text-underline-offset: 4px;
}
.caret-transparent{
    caret-color: transparent;
}
.opacity-0{
    opacity: 0;
}
.opacity-100{
    opacity: 1;
}
.opacity-50{
    opacity: 0.5;
}
.opacity-60{
    opacity: 0.6;
}
.opacity-70{
    opacity: 0.7;
}
.shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md{
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-sm{
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline-none{
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.outline{
    outline-style: solid;
}
.outline-2{
    outline-width: 2px;
}
.outline-critical{
    outline-color: hsl(var(--critical));
}
.outline-warning{
    outline-color: hsl(var(--warning));
}
.outline-white{
    outline-color: #fff;
}
.ring-0{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.ring-2{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.ring-input{
    --tw-ring-color: hsl(var(--input));
}
.ring-neutral-700{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(81 81 81 / var(--tw-ring-opacity, 1));
}
.ring-ring{
    --tw-ring-color: hsl(var(--ring));
}
.ring-transparent{
    --tw-ring-color: transparent;
}
.ring-warning{
    --tw-ring-color: hsl(var(--warning));
}
.ring-offset-1{
    --tw-ring-offset-width: 1px;
}
.ring-offset-2{
    --tw-ring-offset-width: 2px;
}
.ring-offset-background{
    --tw-ring-offset-color: hsl(var(--background));
}
.blur{
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.drop-shadow-\[1px_2px_0px_rgba\(0\2c 0\2c 0\2c 0\.5\)\]{
    --tw-drop-shadow: drop-shadow(1px 2px 0px rgba(0,0,0,0.5));
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.saturate-0{
    --tw-saturate: saturate(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter{
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.backdrop-filter{
    -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}
.transition{
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-all{
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-colors{
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-opacity{
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.transition-transform{
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.duration-200{
    transition-duration: 200ms;
}
.duration-300{
    transition-duration: 300ms;
}
.duration-500{
    transition-duration: 500ms;
}
.ease-in{
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ease-in-out{
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-out{
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
@keyframes enter{
    from{
        opacity: var(--tw-enter-opacity, 1);
        transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
    }
}
@keyframes exit{
    to{
        opacity: var(--tw-exit-opacity, 1);
        transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
    }
}
.animate-in{
    animation-name: enter;
    animation-duration: 150ms;
    --tw-enter-opacity: initial;
    --tw-enter-scale: initial;
    --tw-enter-rotate: initial;
    --tw-enter-translate-x: initial;
    --tw-enter-translate-y: initial;
}
.fade-in{
    --tw-enter-opacity: 0;
}
.fade-in-0{
    --tw-enter-opacity: 0;
}
.fade-in-80{
    --tw-enter-opacity: 0.8;
}
.zoom-in-95{
    --tw-enter-scale: .95;
}
.slide-in-from-bottom{
    --tw-enter-translate-y: 100%;
}
.slide-in-from-bottom-2{
    --tw-enter-translate-y: 0.5rem;
}
.slide-in-from-top{
    --tw-enter-translate-y: -100%;
}
.duration-200{
    animation-duration: 200ms;
}
.duration-300{
    animation-duration: 300ms;
}
.duration-500{
    animation-duration: 500ms;
}
.ease-in{
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ease-in-out{
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ease-out{
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.running{
    animation-play-state: running;
}
.popover-trigger-width{
    width: var(--radix-popover-trigger-width);
}
.scrollbar-hide{
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.scrollbar-hide::-webkit-scrollbar{
    display: none;
}
/* Class used to smooth out exit animations, useful for ux-common components */
.animate-fill-forwards {
    animation-fill-mode: forwards;
  }
/** Darkwing scrollbar styling */
.scrollbar-lg::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
/* broke vue tables */
.\!table {
    display: revert !important;
  }
.table {
    display: revert;
  }
/* required for tailwind's border classes to work */
*,
  ::before,
  ::after {
    border-width: 0;
    border-style: solid;
    border-color: currentColor;
  }
/* Class used to make an lucide icon appear as a classic darkwing status icon */
.status-icon{
    border-radius: 9999px;
    background-color: hsl(var(--primary));
    padding: 0.5rem;
    color: hsl(var(--background));
}
.\[--button-background-color\:theme\(colors\.neutral\.700\)\]{
    --button-background-color: #515151;
}

/** @see https://tailwindcss.com/docs/functions-and-directives#layer */

/** Smooth out shadcn overlay and accordion exit animations. */
.inset-0.bg-black\/80,
.data-\[state\=closed\]\:animate-accordion-up {
  animation-fill-mode: forwards !important;
}
.file\:border-0::file-selector-button{
    border-width: 0px;
}
.file\:bg-transparent::file-selector-button{
    background-color: transparent;
}
.file\:text-sm::file-selector-button{
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.file\:font-medium::file-selector-button{
    font-weight: 500;
}
.placeholder\:text-muted-foreground::-moz-placeholder{
    color: hsl(var(--muted-foreground));
}
.placeholder\:text-muted-foreground::placeholder{
    color: hsl(var(--muted-foreground));
}
.after\:absolute::after{
    content: var(--tw-content);
    position: absolute;
}
.after\:inset-y-0::after{
    content: var(--tw-content);
    top: 0px;
    bottom: 0px;
}
.after\:left-1\/2::after{
    content: var(--tw-content);
    left: 50%;
}
.after\:w-1::after{
    content: var(--tw-content);
    width: 0.25rem;
}
.after\:-translate-x-1\/2::after{
    content: var(--tw-content);
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.focus-within\:relative:focus-within{
    position: relative;
}
.focus-within\:z-20:focus-within{
    z-index: 20;
}
.focus-within\:outline-none:focus-within{
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.focus-within\:ring-2:focus-within{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus-within\:ring-ring:focus-within{
    --tw-ring-color: hsl(var(--ring));
}
.focus-within\:ring-offset-2:focus-within{
    --tw-ring-offset-width: 2px;
}
.hover\:cursor-crosshair:hover{
    cursor: crosshair;
}
.hover\:cursor-pointer:hover{
    cursor: pointer;
}
.hover\:bg-accent:hover{
    background-color: hsl(var(--accent));
}
.hover\:bg-foreground\/50:hover{
    background-color: hsl(var(--foreground) / 0.5);
}
.hover\:bg-input:hover{
    background-color: hsl(var(--input));
}
.hover\:bg-muted:hover{
    background-color: hsl(var(--muted));
}
.hover\:bg-muted\/50:hover{
    background-color: hsl(var(--muted) / 0.5);
}
.hover\:bg-popover:hover{
    background-color: hsl(var(--popover));
}
.hover\:bg-primary:hover{
    background-color: hsl(var(--primary));
}
.hover\:bg-primary\/30:hover{
    background-color: hsl(var(--primary) / 0.3);
}
.hover\:bg-tertiary:hover{
    background-color: hsl(var(--tertiary));
}
.hover\:text-accent-foreground:hover{
    color: hsl(var(--accent-foreground));
}
.hover\:text-foreground:hover{
    color: hsl(var(--foreground));
}
.hover\:text-muted-foreground:hover{
    color: hsl(var(--muted-foreground));
}
.hover\:text-primary-foreground:hover{
    color: hsl(var(--primary-foreground));
}
.hover\:underline:hover{
    text-decoration-line: underline;
}
.hover\:no-underline:hover{
    text-decoration-line: none;
}
.hover\:opacity-100:hover{
    opacity: 1;
}
.hover\:\[--button-background-color\:theme\(colors\.neutral\.600\)\]:hover{
    --button-background-color: #5d5d5d;
}
.focus\:relative:focus{
    position: relative;
}
.focus\:z-10:focus{
    z-index: 10;
}
.focus\:z-\[200\]:focus{
    z-index: 200;
}
.focus\:bg-accent:focus{
    background-color: hsl(var(--accent));
}
.focus\:bg-popover-foreground:focus{
    background-color: hsl(var(--popover-foreground));
}
.focus\:bg-primary:focus{
    background-color: hsl(var(--primary));
}
.focus\:text-accent-foreground:focus{
    color: hsl(var(--accent-foreground));
}
.focus\:text-background:focus{
    color: hsl(var(--background));
}
.focus\:text-primary-foreground:focus{
    color: hsl(var(--primary-foreground));
}
.focus\:outline-none:focus{
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.focus\:ring-0:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus\:ring-1:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus\:ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus\:ring-input:focus{
    --tw-ring-color: hsl(var(--input));
}
.focus\:ring-ring:focus{
    --tw-ring-color: hsl(var(--ring));
}
.focus\:ring-transparent:focus{
    --tw-ring-color: transparent;
}
.focus\:ring-warning:focus{
    --tw-ring-color: hsl(var(--warning));
}
.focus\:ring-offset-1:focus{
    --tw-ring-offset-width: 1px;
}
.focus\:ring-offset-2:focus{
    --tw-ring-offset-width: 2px;
}
.focus-visible\:outline-none:focus-visible{
    outline: 2px solid transparent;
    outline-offset: 2px;
}
.focus-visible\:ring-0:focus-visible{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus-visible\:ring-1:focus-visible{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus-visible\:ring-2:focus-visible{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}
.focus-visible\:ring-ring:focus-visible{
    --tw-ring-color: hsl(var(--ring));
}
.focus-visible\:ring-warning:focus-visible{
    --tw-ring-color: hsl(var(--warning));
}
.focus-visible\:ring-offset-0:focus-visible{
    --tw-ring-offset-width: 0px;
}
.focus-visible\:ring-offset-1:focus-visible{
    --tw-ring-offset-width: 1px;
}
.focus-visible\:ring-offset-2:focus-visible{
    --tw-ring-offset-width: 2px;
}
.focus-visible\:ring-offset-background:focus-visible{
    --tw-ring-offset-color: hsl(var(--background));
}
.disabled\:pointer-events-none:disabled{
    pointer-events: none;
}
.disabled\:pointer-events-auto:disabled{
    pointer-events: auto;
}
.disabled\:cursor-not-allowed:disabled{
    cursor: not-allowed;
}
.disabled\:opacity-50:disabled{
    opacity: 0.5;
}
.disabled\:saturate-0:disabled{
    --tw-saturate: saturate(0);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.peer:disabled ~ .peer-disabled\:cursor-not-allowed{
    cursor: not-allowed;
}
.peer:disabled ~ .peer-disabled\:opacity-70{
    opacity: 0.7;
}
.aria-selected\:bg-accent[aria-selected="true"]{
    background-color: hsl(var(--accent));
}
.aria-selected\:bg-accent\/50[aria-selected="true"]{
    background-color: hsl(var(--accent) / 0.5);
}
.aria-selected\:text-accent-foreground[aria-selected="true"]{
    color: hsl(var(--accent-foreground));
}
.aria-selected\:text-muted-foreground[aria-selected="true"]{
    color: hsl(var(--muted-foreground));
}
.aria-selected\:opacity-100[aria-selected="true"]{
    opacity: 1;
}
.aria-selected\:opacity-30[aria-selected="true"]{
    opacity: 0.3;
}
.data-\[disabled\=true\]\:pointer-events-none[data-disabled="true"]{
    pointer-events: none;
}
.data-\[disabled\]\:pointer-events-none[data-disabled]{
    pointer-events: none;
}
.data-\[panel-group-direction\=vertical\]\:h-px[data-panel-group-direction="vertical"]{
    height: 1px;
}
.data-\[panel-group-direction\=vertical\]\:w-full[data-panel-group-direction="vertical"]{
    width: 100%;
}
.data-\[side\=bottom\]\:translate-y-1[data-side="bottom"]{
    --tw-translate-y: 0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[side\=left\]\:-translate-x-1[data-side="left"]{
    --tw-translate-x: -0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[side\=right\]\:translate-x-1[data-side="right"]{
    --tw-translate-x: 0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[side\=top\]\:-translate-y-1[data-side="top"]{
    --tw-translate-y: -0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[state\=checked\]\:translate-x-5[data-state="checked"]{
    --tw-translate-x: 1.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[state\=unchecked\]\:translate-x-0[data-state="unchecked"]{
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes accordion-up{
    from{
        height: var(--radix-accordion-content-height);
    }
    to{
        height: 0;
    }
}
.data-\[state\=closed\]\:animate-accordion-up[data-state="closed"]{
    animation: accordion-up 0.2s ease-out;
}
@keyframes accordion-down{
    from{
        height: 0;
    }
    to{
        height: var(--radix-accordion-content-height);
    }
}
.data-\[state\=open\]\:animate-accordion-down[data-state="open"]{
    animation: accordion-down 0.2s ease-out;
}
.data-\[panel-group-direction\=vertical\]\:flex-col[data-panel-group-direction="vertical"]{
    flex-direction: column;
}
.data-\[state\=active\]\:border-primary[data-state="active"]{
    border-color: hsl(var(--primary));
}
.data-\[state\=checked\]\:border-primary[data-state="checked"]{
    border-color: hsl(var(--primary));
}
.data-\[active\]\:bg-accent\/50[data-active]{
    background-color: hsl(var(--accent) / 0.5);
}
.data-\[selected\=\'true\'\]\:bg-accent[data-selected='true']{
    background-color: hsl(var(--accent));
}
.data-\[state\=active\]\:bg-background[data-state="active"]{
    background-color: hsl(var(--background));
}
.data-\[state\=checked\]\:bg-conserving[data-state="checked"]{
    background-color: hsl(var(--conserving));
}
.data-\[state\=checked\]\:bg-primary[data-state="checked"]{
    background-color: hsl(var(--primary));
}
.data-\[state\=on\]\:bg-accent[data-state="on"]{
    background-color: hsl(var(--accent));
}
.data-\[state\=open\]\:bg-accent[data-state="open"]{
    background-color: hsl(var(--accent));
}
.data-\[state\=open\]\:bg-accent\/50[data-state="open"]{
    background-color: hsl(var(--accent) / 0.5);
}
.data-\[state\=open\]\:bg-secondary[data-state="open"]{
    background-color: hsl(var(--secondary));
}
.data-\[state\=selected\]\:bg-muted[data-state="selected"]{
    background-color: hsl(var(--muted));
}
.data-\[state\=unchecked\]\:bg-input[data-state="unchecked"]{
    background-color: hsl(var(--input));
}
.data-\[selected\=true\]\:text-accent-foreground[data-selected="true"]{
    color: hsl(var(--accent-foreground));
}
.data-\[state\=active\]\:text-foreground[data-state="active"]{
    color: hsl(var(--foreground));
}
.data-\[state\=checked\]\:text-primary-foreground[data-state="checked"]{
    color: hsl(var(--primary-foreground));
}
.data-\[state\=closed\]\:text-muted-foreground[data-state="closed"]{
    color: hsl(var(--muted-foreground));
}
.data-\[state\=on\]\:text-accent-foreground[data-state="on"]{
    color: hsl(var(--accent-foreground));
}
.data-\[state\=open\]\:text-accent-foreground[data-state="open"]{
    color: hsl(var(--accent-foreground));
}
.data-\[state\=open\]\:text-foreground[data-state="open"]{
    color: hsl(var(--foreground));
}
.data-\[state\=open\]\:text-muted-foreground[data-state="open"]{
    color: hsl(var(--muted-foreground));
}
.data-\[disabled\=true\]\:opacity-50[data-disabled="true"]{
    opacity: 0.5;
}
.data-\[disabled\]\:opacity-50[data-disabled]{
    opacity: 0.5;
}
.data-\[state\=active\]\:shadow-sm[data-state="active"]{
    --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.data-\[state\=closed\]\:duration-300[data-state="closed"]{
    transition-duration: 300ms;
}
.data-\[state\=open\]\:duration-500[data-state="open"]{
    transition-duration: 500ms;
}
.data-\[motion\^\=from-\]\:animate-in[data-motion^="from-"]{
    animation-name: enter;
    animation-duration: 150ms;
    --tw-enter-opacity: initial;
    --tw-enter-scale: initial;
    --tw-enter-rotate: initial;
    --tw-enter-translate-x: initial;
    --tw-enter-translate-y: initial;
}
.data-\[state\=open\]\:animate-in[data-state="open"]{
    animation-name: enter;
    animation-duration: 150ms;
    --tw-enter-opacity: initial;
    --tw-enter-scale: initial;
    --tw-enter-rotate: initial;
    --tw-enter-translate-x: initial;
    --tw-enter-translate-y: initial;
}
.data-\[state\=visible\]\:animate-in[data-state="visible"]{
    animation-name: enter;
    animation-duration: 150ms;
    --tw-enter-opacity: initial;
    --tw-enter-scale: initial;
    --tw-enter-rotate: initial;
    --tw-enter-translate-x: initial;
    --tw-enter-translate-y: initial;
}
.data-\[motion\^\=to-\]\:animate-out[data-motion^="to-"]{
    animation-name: exit;
    animation-duration: 150ms;
    --tw-exit-opacity: initial;
    --tw-exit-scale: initial;
    --tw-exit-rotate: initial;
    --tw-exit-translate-x: initial;
    --tw-exit-translate-y: initial;
}
.data-\[state\=closed\]\:animate-out[data-state="closed"]{
    animation-name: exit;
    animation-duration: 150ms;
    --tw-exit-opacity: initial;
    --tw-exit-scale: initial;
    --tw-exit-rotate: initial;
    --tw-exit-translate-x: initial;
    --tw-exit-translate-y: initial;
}
.data-\[state\=hidden\]\:animate-out[data-state="hidden"]{
    animation-name: exit;
    animation-duration: 150ms;
    --tw-exit-opacity: initial;
    --tw-exit-scale: initial;
    --tw-exit-rotate: initial;
    --tw-exit-translate-x: initial;
    --tw-exit-translate-y: initial;
}
.data-\[motion\^\=from-\]\:fade-in[data-motion^="from-"]{
    --tw-enter-opacity: 0;
}
.data-\[motion\^\=to-\]\:fade-out[data-motion^="to-"]{
    --tw-exit-opacity: 0;
}
.data-\[state\=closed\]\:fade-out-0[data-state="closed"]{
    --tw-exit-opacity: 0;
}
.data-\[state\=hidden\]\:fade-out[data-state="hidden"]{
    --tw-exit-opacity: 0;
}
.data-\[state\=open\]\:fade-in-0[data-state="open"]{
    --tw-enter-opacity: 0;
}
.data-\[state\=visible\]\:fade-in[data-state="visible"]{
    --tw-enter-opacity: 0;
}
.data-\[state\=closed\]\:zoom-out-95[data-state="closed"]{
    --tw-exit-scale: .95;
}
.data-\[state\=open\]\:zoom-in-90[data-state="open"]{
    --tw-enter-scale: .9;
}
.data-\[state\=open\]\:zoom-in-95[data-state="open"]{
    --tw-enter-scale: .95;
}
.data-\[motion\=from-end\]\:slide-in-from-right-52[data-motion="from-end"]{
    --tw-enter-translate-x: 13rem;
}
.data-\[motion\=from-start\]\:slide-in-from-left-52[data-motion="from-start"]{
    --tw-enter-translate-x: -13rem;
}
.data-\[motion\=to-end\]\:slide-out-to-right-52[data-motion="to-end"]{
    --tw-exit-translate-x: 13rem;
}
.data-\[motion\=to-start\]\:slide-out-to-left-52[data-motion="to-start"]{
    --tw-exit-translate-x: -13rem;
}
.data-\[side\=bottom\]\:slide-in-from-top-2[data-side="bottom"]{
    --tw-enter-translate-y: -0.5rem;
}
.data-\[side\=left\]\:slide-in-from-right-2[data-side="left"]{
    --tw-enter-translate-x: 0.5rem;
}
.data-\[side\=right\]\:slide-in-from-left-2[data-side="right"]{
    --tw-enter-translate-x: -0.5rem;
}
.data-\[side\=top\]\:slide-in-from-bottom-2[data-side="top"]{
    --tw-enter-translate-y: 0.5rem;
}
.data-\[state\=closed\]\:slide-out-to-bottom[data-state="closed"]{
    --tw-exit-translate-y: 100%;
}
.data-\[state\=closed\]\:slide-out-to-left[data-state="closed"]{
    --tw-exit-translate-x: -100%;
}
.data-\[state\=closed\]\:slide-out-to-left-1\/2[data-state="closed"]{
    --tw-exit-translate-x: -50%;
}
.data-\[state\=closed\]\:slide-out-to-right[data-state="closed"]{
    --tw-exit-translate-x: 100%;
}
.data-\[state\=closed\]\:slide-out-to-top[data-state="closed"]{
    --tw-exit-translate-y: -100%;
}
.data-\[state\=closed\]\:slide-out-to-top-\[48\%\][data-state="closed"]{
    --tw-exit-translate-y: -48%;
}
.data-\[state\=open\]\:slide-in-from-bottom[data-state="open"]{
    --tw-enter-translate-y: 100%;
}
.data-\[state\=open\]\:slide-in-from-left[data-state="open"]{
    --tw-enter-translate-x: -100%;
}
.data-\[state\=open\]\:slide-in-from-left-1\/2[data-state="open"]{
    --tw-enter-translate-x: -50%;
}
.data-\[state\=open\]\:slide-in-from-right[data-state="open"]{
    --tw-enter-translate-x: 100%;
}
.data-\[state\=open\]\:slide-in-from-top[data-state="open"]{
    --tw-enter-translate-y: -100%;
}
.data-\[state\=open\]\:slide-in-from-top-\[48\%\][data-state="open"]{
    --tw-enter-translate-y: -48%;
}
.data-\[state\=closed\]\:duration-300[data-state="closed"]{
    animation-duration: 300ms;
}
.data-\[state\=open\]\:duration-500[data-state="open"]{
    animation-duration: 500ms;
}
.data-\[panel-group-direction\=vertical\]\:after\:left-0[data-panel-group-direction="vertical"]::after{
    content: var(--tw-content);
    left: 0px;
}
.data-\[panel-group-direction\=vertical\]\:after\:h-1[data-panel-group-direction="vertical"]::after{
    content: var(--tw-content);
    height: 0.25rem;
}
.data-\[panel-group-direction\=vertical\]\:after\:w-full[data-panel-group-direction="vertical"]::after{
    content: var(--tw-content);
    width: 100%;
}
.data-\[panel-group-direction\=vertical\]\:after\:-translate-y-1\/2[data-panel-group-direction="vertical"]::after{
    content: var(--tw-content);
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.data-\[panel-group-direction\=vertical\]\:after\:translate-x-0[data-panel-group-direction="vertical"]::after{
    content: var(--tw-content);
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.group[data-state="open"] .group-data-\[state\=open\]\:rotate-180{
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@media (min-width: 640px){
    .sm\:mt-0{
        margin-top: 0px;
    }
    .sm\:max-w-full{
        max-width: 100%;
    }
    .sm\:max-w-sm{
        max-width: 24rem;
    }
    .sm\:flex-row{
        flex-direction: row;
    }
    .sm\:justify-end{
        justify-content: flex-end;
    }
    .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]){
        --tw-space-x-reverse: 0;
        margin-right: calc(0.5rem * var(--tw-space-x-reverse));
        margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
    }
    .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]){
        --tw-space-x-reverse: 0;
        margin-right: calc(1rem * var(--tw-space-x-reverse));
        margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
    }
    .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]){
        --tw-space-y-reverse: 0;
        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(0px * var(--tw-space-y-reverse));
    }
    .sm\:rounded-lg{
        border-radius: calc(var(--radius) * 2);
    }
    .sm\:text-left{
        text-align: left;
    }
}
@media (min-width: 768px){
    .md\:absolute{
        position: absolute;
    }
    .md\:w-\[var\(--radix-navigation-menu-viewport-width\)\]{
        width: var(--radix-navigation-menu-viewport-width);
    }
    .md\:w-auto{
        width: auto;
    }
    .md\:max-w-full{
        max-width: 100%;
    }
    .md\:px-4{
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
.\[\&\.accordion__item\]\:min-w-\[980px\].accordion item{
    min-width: 980px;
}
.\[\&\:\:-webkit-inner-spin-button\]\:appearance-none::-webkit-inner-spin-button{
    -webkit-appearance: none;
            appearance: none;
}
.\[\&\:has\(\[aria-selected\]\)\]\:bg-accent:has([aria-selected]){
    background-color: hsl(var(--accent));
}
.first\:\[\&\:has\(\[aria-selected\]\)\]\:rounded-l-md:has([aria-selected]):first-child{
    border-top-left-radius: calc(var(--radius));
    border-bottom-left-radius: calc(var(--radius));
}
.last\:\[\&\:has\(\[aria-selected\]\)\]\:rounded-r-md:has([aria-selected]):last-child{
    border-top-right-radius: calc(var(--radius));
    border-bottom-right-radius: calc(var(--radius));
}
.\[\&\:has\(\[aria-selected\]\.day-outside\)\]\:bg-accent\/50:has([aria-selected].day-outside){
    background-color: hsl(var(--accent) / 0.5);
}
.\[\&\:has\(\[aria-selected\]\.day-range-end\)\]\:rounded-r-md:has([aria-selected].day-range-end){
    border-top-right-radius: calc(var(--radius));
    border-bottom-right-radius: calc(var(--radius));
}
.\[\&\:has\(\[role\=checkbox\]\)\]\:pr-0:has([role=checkbox]){
    padding-right: 0px;
}
.\[\&\>div\]\:overflow-x-clip>div{
    overflow-x: clip;
}
.\[\&\>h3\]\:mt-3>h3{
    margin-top: 0.75rem;
}
.\[\&\>h3\]\:font-bold>h3{
    font-weight: 700;
}
.\[\&\>span\]\:line-clamp-1>span{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
.\[\&\>span\]\:text-base>span{
    font-size: 1rem;
    line-height: 1.5rem;
}
.\[\&\>span\]\:text-foreground>span{
    color: hsl(var(--foreground));
}
.\[\&\>svg\]\:h-6>svg{
    height: 1.5rem;
}
.\[\&\>svg\]\:w-6>svg{
    width: 1.5rem;
}
.\[\&\>svg\]\:rounded-xl>svg{
    border-radius: calc(var(--radius) * 3);
}
.\[\&\>svg\]\:bg-popover>svg{
    background-color: hsl(var(--popover));
}
.\[\&\>tbody\>\*\:nth-child\(odd\)\>td\]\:bg-\[\#353535\]>tbody>*:nth-child(odd)>td{
    --tw-bg-opacity: 1;
    background-color: rgb(53 53 53 / var(--tw-bg-opacity, 1));
}
.\[\&\>tbody\>tr\]\:h-10>tbody>tr{
    height: 2.5rem;
}
.\[\&\>tbody\>tr\]\:h-12>tbody>tr{
    height: 3rem;
}
.\[\&\>tbody\>tr\]\:h-5>tbody>tr{
    height: 1.25rem;
}
.\[\&\>tbody\>tr\]\:h-8>tbody>tr{
    height: 2rem;
}
.\[\&\>tr\>td\]\:px-\[0\.3125rem\]>tr>td{
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
}
.\[\&\>tr\>th\:first-child\]\:rounded-tl-sm>tr>th:first-child{
    border-top-left-radius: calc(var(--radius) - 4px);
}
.\[\&\>tr\>th\:last-child\]\:rounded-tr-sm>tr>th:last-child{
    border-top-right-radius: calc(var(--radius) - 4px);
}
.\[\&\>tr\>th\]\:px-\[0\.3125rem\]>tr>th{
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
}
.\[\&\>tr\]\:h-8>tr{
    height: 2rem;
}
.\[\&\>tr\]\:last\:border-b-0:last-child>tr{
    border-bottom-width: 0px;
}
.\[\&\[data-panel-group-direction\=vertical\]\>div\]\:rotate-90[data-panel-group-direction=vertical]>div{
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\[\&\[data-state\=closed\]\>svg\]\:-rotate-90[data-state=closed]>svg{
    --tw-rotate: -90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\[\&\[data-state\=open\]\>svg\.lucide-chevron-down\]\:rotate-180[data-state=open]>svg.lucide-chevron-down{
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\[\&\[data-state\=open\]\>svg\.lucide-chevron-right\]\:rotate-90[data-state=open]>svg.lucide-chevron-right{
    --tw-rotate: 90deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\[\&\[data-state\=open\]\>svg\]\:rotate-0[data-state=open]>svg{
    --tw-rotate: 0deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.\[\&\[data-state\=open\]\]\:bg-tertiary[data-state=open]{
    background-color: hsl(var(--tertiary));
}
.\[\&_\*\]\:bg-tertiary *{
    background-color: hsl(var(--tertiary));
}
.\[\&_\.filter-option\.selected_div\]\:font-bold .filter-option.selected div{
    font-weight: 700;
}
.\[\&_\.filter-option\:not\(\.selected\)\]\:text-foreground\/50 .filter-option:not(.selected){
    color: hsl(var(--foreground) / 0.5);
}
.\[\&_\[cmdk-group-heading\]\]\:px-2 [cmdk-group-heading]{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.\[\&_\[cmdk-group-heading\]\]\:py-1\.5 [cmdk-group-heading]{
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
}
.\[\&_\[cmdk-group-heading\]\]\:text-xs [cmdk-group-heading]{
    font-size: 0.75rem;
    line-height: 1rem;
}
.\[\&_\[cmdk-group-heading\]\]\:font-medium [cmdk-group-heading]{
    font-weight: 500;
}
.\[\&_\[cmdk-group-heading\]\]\:uppercase [cmdk-group-heading]{
    text-transform: uppercase;
}
.\[\&_\[cmdk-group-heading\]\]\:text-muted-foreground [cmdk-group-heading]{
    color: hsl(var(--muted-foreground));
}
.\[\&_\[cmdk-group\]\:not\(\[hidden\]\)_\~\[cmdk-group\]\]\:pt-0 [cmdk-group]:not([hidden]) ~[cmdk-group]{
    padding-top: 0px;
}
.\[\&_\[cmdk-group\]\]\:px-2 [cmdk-group]{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.\[\&_\[cmdk-input-wrapper\]_svg\]\:h-5 [cmdk-input-wrapper] svg{
    height: 1.25rem;
}
.\[\&_\[cmdk-input-wrapper\]_svg\]\:w-5 [cmdk-input-wrapper] svg{
    width: 1.25rem;
}
.\[\&_\[cmdk-input\]\]\:h-12 [cmdk-input]{
    height: 3rem;
}
.\[\&_\[cmdk-item\]\]\:px-2 [cmdk-item]{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.\[\&_\[cmdk-item\]\]\:py-3 [cmdk-item]{
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.\[\&_\[cmdk-item\]_svg\]\:h-5 [cmdk-item] svg{
    height: 1.25rem;
}
.\[\&_\[cmdk-item\]_svg\]\:w-5 [cmdk-item] svg{
    width: 1.25rem;
}
.\[\&_button\]\:h-\[2\.1875rem\] button{
    height: 2.1875rem;
}
.\[\&_input\]\:\!h-\[2\.1875rem\] input{
    height: 2.1875rem !important;
}
.\[\&_p\]\:leading-relaxed p{
    line-height: 1.625;
}
.\[\&_svg\]\:h-3 svg{
    height: 0.75rem;
}
.\[\&_svg\]\:w-3 svg{
    width: 0.75rem;
}
.\[\&_svg\]\:align-middle svg{
    vertical-align: middle;
}
.\[\&_table\]\:w-full table{
    width: 100%;
}
.\[\&_table\]\:border-spacing-0 table{
    --tw-border-spacing-x: 0px;
    --tw-border-spacing-y: 0px;
    border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}
.\[\&_table\]\:pr-\[0\.0625rem\] table{
    padding-right: 0.0625rem;
}
.\[\&_table_td\]\:p-0\.5 table td{
    padding: 0.125rem;
}
.\[\&_td\>span\>div\]\:max-h-\[2\.3125rem\] td>span>div{
    max-height: 2.3125rem;
}
.\[\&_td\]\:\!px-0\.5 td{
    padding-left: 0.125rem !important;
    padding-right: 0.125rem !important;
}
.\[\&_td\]\:\!py-\[0\.0625rem\] td{
    padding-top: 0.0625rem !important;
    padding-bottom: 0.0625rem !important;
}
.\[\&_tr\:last-child\]\:border-0 tr:last-child{
    border-width: 0px;
}
.\[\&_tr\]\:h-9 tr{
    height: 2.25rem;
}
.\[\&_tr\]\:p-0 tr{
    padding: 0px;
}
