.step-view .base,
.step-view.base {
  margin: 0px;
  padding: 0px;
  display: flex;
  box-sizing: border-box;
}
.step-view.base {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 200px; /* Hardcoded height -- to display in the modal right.*/
  flex-direction: column;
}
.step-view .step-view-step.base {
  background: #444;
  width: 100%;
  min-height: 50px;
  max-height: 200px;
  color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  padding: 10px;
  border-radius: 5px;
  margin-top: 1px;
  margin-bottom: 1px;
}
.step-view .step-view-step-message.base {
  color: #ddd;
  text-align: start;
  overflow: scroll;
  height: 100%;
  max-height: 200px;
}
