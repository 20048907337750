.user-input-form input.warning,
.user-input-form div.warning {
  outline: 2px solid #ffcc16 !important;
}

.user-input-form input.error,
div.error {
  outline: 2px solid #df4b54 !important;
}

.user-input-form select.empty {
  color: #757575 !important;
}
