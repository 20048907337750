.base {
  margin: 0px;
  padding: 0px;
  display: flex;
  box-sizing: border-box;
}
/* Hardcoded width and height */
.big-card {
  min-width: 680px;
  min-height: 560px;
  height: 100%;
  background: #222;
  border-radius: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 30px;
}
.big-card .base.header {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.big-card h3 {
  color: #fff;
  font-size: 35px;
  line-height: 50px;
  font-weight: 500;
  width: 100%;
}
.big-card .base.header button {
  width: 200px;
  height: 50px;
  border: 2px solid #444;
  border-radius: 10px;
  background: #fff;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  line-height: 50px;
  justify-content: center;
}
.big-card h3.bold {
  font-weight: 700;
}
.big-card p.subtle {
  color: #999;
  font-size: 16px;
  line-height: 30px;
  font-weight: 500;
  margin-left: 10px;
}
.big-card div.badge-area {
  flex-direction: row;
  justify-content: center;
  align-self: flex-start;
  margin: 20px 0px;
  min-width: 200px;
}
.big-card div.badge {
  background: #ccc;
  border-radius: 5px;
  padding: 0px 10px;
  color: #222;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  margin-right: 10px;
}
.big-card div.badge.active {
  background: #46d401;
  border-radius: 5px;
  padding: 0px 10px;
  color: #222;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
}
.big-card div.badge.inactive {
  background: #ed3641;
  border-radius: 5px;
  padding: 0px 10px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
}
.big-card div.card-body {
  width: 100%;
  height: 100%;
  min-height: 300px;
}
.big-card .card-footer {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.big-card .card-footer input {
  min-width: 300px;
  width: 75%;
  border: 2px solid #444;
  color: #fff;
  border-radius: 10px;
  background: #222;
  justify-content: flex-start;
  font-weight: 500px;
  font-size: 16px;
  line-height: 30px;
  padding: 10px 20px;
  margin-right: 5px;
}
.big-card .card-footer .dropdown-button {
  min-width: 200px;
  width: 25%;
  margin-right: 20px;
}
.big-card .card-footer button {
  width: 200px;
  height: 50px;
  border: 2px solid #444;
  border-radius: 10px;
  background: #fff;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  line-height: 50px;
  justify-content: center;
}
.big-card .metric-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0px;
  width: 100%;
}
.big-card .metric {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 50px;
  background: #333333;
  color: #ffffff;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 50px;
  border-radius: 5px;
  margin-bottom: 1px;
  margin-top: 1px;
  padding: 0px 30px;
}
.big-card .metric.header {
  color: #999999;
  background: #444444;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 50px;
}
.big-card .metric .metric-label {
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.big-card .metric .metric-label a {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.big-card .metric .metric-value {
  justify-content: center;
  align-items: center;
  width: 100px;
}
.big-card .metric .metric-diff {
  justify-content: center;
  align-items: center;
  width: 80px;
  margin: 10px;
  color: #999;
  border-radius: 5px;
}
.big-card .metric .metric-diff.add {
  background: #46d401;
  color: #222;
}
.big-card .metric .metric-diff.add::before {
  content: '+';
}
.big-card .metric .metric-diff.del {
  background: #ed3641;
  color: #fff;
}
.big-card .metric .metric-diff.chg {
  background: yellow;
  color: #222;
}
.big-card button.active {
  background: #46d401;
  color: #222;
}

.big-card button.half {
  width: 50%;
  min-width: 80px;
}
.big-card button.half:first-child {
  margin-right: 20px;
}
