.confirm-modal.base,
.confirm-modal .base {
  margin: 0px;
  padding: 0px;
  display: flex;
  box-sizing: border-box;
}

.confirm-modal.base {
  width: 550px;
  min-height: 300px;
  height: -moz-fit-content;
  height: fit-content;
  background: #333333;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}
.confirm-modal .confirm-modal-header {
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  margin-bottom: 20px;
}
.confirm-modal-body {
  min-height: 200px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  text-align: start;
}
.base .confirm-modal-footer {
  width: 100%;
  height: 60px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.confirm-modal button {
  min-width: -moz-fit-content;
  min-width: fit-content;
  width: 100%;
  height: 50px;
  border: 2px solid #444;
  border-radius: 10px;
  background: #fff;
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  line-height: 50px;
  justify-content: center;
}
.confirm-modal button.primary {
  background: #fff;
  color: #000000;
}
.confirm-modal button.secondary {
  background: #444;
  color: #fff;
}
